:root {
    --green: #1a7f64;
    --green-light: #19c37d;
    --green-transparent: #19c37d21;
    --red: #7a0f01;
    --red-transparent: #ff050542;
    --yellow: #885800;
    --yellow-transparent: #ffa6014d;
    --gray: #818181;
    --gray-transparent: #81818121;
    --gray-1: #f7f7f8;
    --gray-light: #e4e4e9;
    --gray2: #cbcbcc;
    --black: #202123;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--black);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.link {
    color: var(--green-light);
    font-weight: 500;
}
.link:hover {
    color: var(--green);
}
a {
    color: inherit;
    text-decoration: none;
}
.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
header {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray-light);
    padding: 0 16px;
}
.logo {
    font-size: 1.4rem;
    font-weight: bold;
}
header nav {
    display: flex;
    align-items: center;
}
header nav a {
    margin-left: 24px;
}

.content {
    display: flex;
    flex: 1;
}
.nav {
    width: 230px;
    border-right: 1px solid var(--gray-light);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.nav nav {
    margin-top: 16px;
}
.nav li {
    height: 40px;
    display: flex;
    align-items: center;
    color: var(--black);
}
.nav li span {
    margin-bottom: 2px;
}
.nav li.selected,
.nav li.selected:hover {
    background-color: var(--green-transparent);
    color: var(--green);
}
.nav li:hover {
    cursor: pointer;
    background-color: var(--gray-1);
}
.nav img,
.nav svg {
    stroke: #292d32;
    height: 20px;
    width: 20px;
    margin: 0 12px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}
.nav .selected svg {
    stroke: var(--green);
}
.disclaimer {
    font-size: x-small;
    margin: 8px;
}

/* ==== REPORT ==== */
.report {
    flex: 1;
    box-sizing: border-box;
    max-height: calc(100vh - 62px);
    padding: 0 24px 24px 24px;
    overflow-y: scroll;
    justify-content: flex-start;
}
.report-placeholder,
.report-loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.report-placeholder > p {
    max-width: 300px;
    text-align: center;
}
.report-summary li {
    margin-top: 8px;
}
.qr-card {
    border: 4px dashed var(--gray2);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    min-width: 250px;
    justify-content: space-between;
    position: relative;
}
.qr-card p {
    font-size: x-small;
}
.qr-card h4 {
    margin: 0;
}

/* RECOMMENDATIONS  */
.recommendations {
    flex: 1;
}
.population,
.priority {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    text-transform: capitalize;
}
.icon {
    height: 16px;
    margin: 0 4px;
}

.recommendation,
.report-result {
    border-bottom: 1px solid var(--gray-light);
}
/* RESULTS */
.recommendation > p,
.report-result > p {
    margin-top: 0;
}
.implications td {
    border-top: 1px solid var(--gray-light);
}

.implications td:last-child {
    padding-right: 0;
}
.implications td:first-child {
    padding-left: 0;
}
.info-box {
    padding: 16px;
    background-color: var(--gray-1);
    border: 1px solid var(--gray-light);
    border-radius: 4px;
}

/* ==== FORM ==== */
.form {
    width: 320px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--gray-light);
}
.result {
    margin-bottom: 8px;
}
.variants {
    flex: 2;
    display: flex;
    flex-direction: column;
}
.variants > textarea {
    width: 100%;
    flex: 1;
    margin-bottom: 16px;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid var(--gray2);
    border-radius: 4px;
    outline-color: #2684ff;
    font-size: large;
}
.button-container {
    display: flex;
}
.button-container > button {
    padding: 10px 0;
    margin-bottom: 16px;
    border: none;
    border-radius: 24px;
    flex: 1;
    margin-right: 16px;
    font-weight: bold;
}

/* ==== GENERIC ==== */
.green {
    color: var(--green);
    background-color: var(--green-transparent);
    border: 1px solid var(--green);
}
.yellow {
    color: var(--yellow);
    background-color: var(--yellow-transparent);
    border: 1px solid var(--yellow);
}
.red {
    color: var(--red);
    background-color: var(--red-transparent);
    border: 1px solid var(--red);
}
.gray {
    color: var(--gray);
    background-color: var(--gray-transparent);
    border: 1px solid var(--gray);
}
.space-between {
    display: flex;
    justify-content: space-between;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
table {
    border-collapse: collapse;
    margin: 16px 0;
}
th {
    text-align: left;
    font-weight: 500;
    padding-bottom: 4px;
}
tr {
    padding: 8px 0;
}
td {
    padding: 8px 16px 8px 0;
    vertical-align: top;
}
button.primary {
    background-color: var(--green-light);
    color: white;
}
button.secondary {
    background-color: var(--gray-light);
}
button:hover {
    cursor: pointer;
}
.tags {
    display: flex;
    margin-bottom: 16px;
}
.tag {
    padding: 8px 16px;
    border-radius: 4px;
    margin-right: 16px;
}
.tabs {
    padding: 0;
    display: flex;
    border-bottom: 1px solid var(--gray2);
    margin-bottom: 0;
    margin-top: 36px;
}
.tab-title {
    padding: 12px 16px;
    font-weight: 600;
    font-size: 1em;
    border: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    border-radius: 0;
    color: var(--black);
}
.tab-title.active {
    border-bottom: 2px solid var(--green-light);
    color: var(--green-light);
}

@media print {
    .no-print {
        display: none !important;
    }
    .no-break {
        break-inside: avoid;
    }
    .report {
        width: 100%;
        height: 100%;
        overflow: visible;
    }
}
